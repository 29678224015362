/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h3, null, "Hola!"), "\n", React.createElement(_components.p, null, "¿Cómo estás? Espero que muy bien. Yo estoy feliz de que hayas llegado hasta aquí. Para mí, esta es una gran oportunidad para contarles sobre mi servicio a aquellos que lo necesiten y lo deseen. Yo atiendo tanto de manera presencial como en línea, para personas de todo el mundo."), "\n", React.createElement(_components.p, null, "Hace varios años, comencé un camino de consciencia en búsqueda de sanar un síntoma que no me dejaba vivir en plenitud. Hoy, el síntoma sanó y mi relación con la vida y la realidad adquirió significado."), "\n", React.createElement(_components.p, null, "Lógicamente, mi camino continúa y continuará, ya que aún hay muchas distorsiones por seguir sanando en mí y el afán de estar en coherencia sigue intacto."), "\n", React.createElement(_components.p, null, "Asimismo, este camino se expandió. Hoy acompaño a personas que quieren sanar y también comprender la profundidad del mensaje que trae un síntoma físico, emocional, vincular o económico a sus vidas. Valientes que deseen traer la plenitud a su experiencia humana."), "\n", React.createElement(_components.p, null, "Se puede transformar, sanar y decodificar todo tipo de síntoma, problemática o dolor en tu vida que te haga vivir de una manera NO plena. Este mismo se aborda en un “camino de consultas”, cada consulta dura 2 horas y se lleva a cabo una vez cada 30 días. La misma tiene un orden y una lógica: desde tu presente, hacia tu pasado, tu gestación y tu ancestralidad."), "\n", React.createElement(_components.p, null, "Soy consultora certificada en Decodificación Bioemocional y en Bioexistencia Consciente, con la escuela de Humano Puente (", React.createElement(_components.a, {
    href: "http://www.humanopuente.com.ar/"
  }, "www.humanopuente.com.ar"), "). Esta es una corriente espiritual con bases biológicas, donde hacemos hincapié en la reprogramación del inconsciente individual y familiar, teniendo en cuenta que el inconsciente (alma) se programa por el éxito y por el fracaso biológico, y que cada síntoma es un recuerdo doloroso no sanado. Este mismo llega por evolución y lógica biológica, y además por emociones guardadas."), "\n", React.createElement(_components.p, null, "Somos la expresión de lo contenido y la buena noticia es que podemos modificarlo a un nivel más profundo que el de la mente consciente, el inconsciente!"), "\n", React.createElement(_components.p, null, "Si te interesa transformar algo en tu vida y quieres saber más sobre el modo de trabajar o solo preguntar sobre algún síntoma en específico, puedes hacer clic en el botón “Agendar” y tenemos una reunión gratuita, o contactarme vía WhatsApp / correo electrónico."), "\n", React.createElement(_components.p, null, "Te acompaño a transitar en orden y coherencia el camino de tu sanación."), "\n", React.createElement(_components.p, null, "Te abrazo grande y"), "\n", React.createElement(_components.h3, null, "¡Muchas gracias! :)"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
